import $ from "jquery";
import _ from 'underscore';
import Backbone from 'backbone';
import {TransferChooserBaseView, ImportController, ImportChooserView} from "@riag-libs/drtax.client-common";

const ImportChooserViewUr = ImportChooserView.extend({

})

class ImportControllerUr extends ImportController {

  createTransferChooserViewInstance(args) {
    args.model.set('iconName', "ur-logo")
    return new ImportChooserViewUr(args)
  }

  createImportChooserViewInstance(args) {
    args.model.set('iconName', "ur-logo")
    return super.createImportChooserViewInstance(args);
  }

};

export {ImportChooserViewUr, ImportControllerUr};
