import Backbone from 'backbone';

import {appConfiguration, DocumentController} from "@riag-libs/drtax.client-common";
import DocumentEditViewUr from "./document-edit-view-ur";
import CreateNewController from "./createnew/create-new-controller-ur";

const CreateNewDocModel = Backbone.Model.extend({
  defaults: {
    view: 'default', // ('default' | 'secondary')
    pidNumber: '',
    identificationCode: '',
    simplified: '' // ('', 'true', 'false')
  },
  isSimplifiedDeclaration: function () {
    if (this.get('view') === 'secondary') {
      return !this.get('business') && this.get('simplified') === 'true';
    }
  },
  isSimplifiedChosen: function() {
    return this.get('business') || this.get('simplified') === 'true' || this.get('simplified') === 'false';
  }
});

class DocumentControllerUr extends DocumentController {

  createDocumentEditViewInstance(options) {
    return new DocumentEditViewUr(options);
  }

  createCreateNewDocView(router, groupId) {
    const self = this;
    const resolvedGroupId = groupId ? groupId : appConfiguration.defaultGroupId;

    return new Promise(
      function (resolve, reject) {
        const createNewController = new CreateNewController({
          documentService: self.documentService,
          sessionModel: self.sessionModel,
          groupId: resolvedGroupId
        })
        createNewController.initialize()
        const view = createNewController.mainView
        resolve(view)
      }
    );
  }

}

export default DocumentControllerUr
