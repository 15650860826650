import _ from "underscore";
import {CredsStepView} from "@riag-libs/drtax.client-common";
import whereIsMyCodeHtml from "./whereismycode.html";

const CredStepViewUr = CredsStepView.extend({
  whereismycode_template: _.template(whereIsMyCodeHtml),

  createWhereismycode: function() {
    return this.whereismycode_template({
      imageUrl: 'img/help/whereismycode_de.png'
    })
  }
})
export default CredStepViewUr
