import Backbone from "backbone";

import {CreateNewController, SecondaryModel, SecondaryView} from "@riag-libs/drtax.client-common";

import CredStepView from "./creds-view-ur";

class CreateNewControllerUr extends CreateNewController {

  initialize() {
    super.initialize();
    this.models.secondary = new SecondaryModel({})
  }

  onNext() {
    const state = this.mainModel.get('currentState')
    if (state == 'secondary') {
      if (!this.validateCurrentModel())
        return
      const params = this.createSubmitParams()
      this.callServer(params)
    } else {
      super.onNext()
    }
  }

  createSubview(state) {
    if (state == 'secondary') {
      let view = new SecondaryView({model: this.models.secondary})
      view.render()
      return view
    }
    if (state == 'creds') {
      let view = new CredStepView({model: this.models.creds})
      view.render()
      return view
    }
    return super.createSubview(state);
  }

  createSubmitParams() {
    const params = super.createSubmitParams()
    if (this.models.secondary.get('active')) {
      params.EASY = this.models.secondary.isSimplifiedDeclaration()
    }
    return params
  }

  processCreateResponse(response) {
    const secondaryNeeded = response.incomplete && response.additionalData.NO_SECONDARY_INFO
    this.mainModel.set('secondaryNeeded', secondaryNeeded)
    if (secondaryNeeded) {
      this.models.secondary.clear().set({
        'intercantonal': response.additionalData.SWISS_DOMICILE,
        'taxYear': response.additionalData.STEUERPERIODE
      })
      this.changeState('secondary')
    } else {
      super.processCreateResponse(response)
    }
  }

}
export default CreateNewControllerUr
