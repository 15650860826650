import fecha from 'fecha/fecha';
import {DocumentChooserController, DocumentChooserView, DeleteDocumentView, MenuFactory, appConfiguration} from "@riag-libs/drtax.client-common";

class DocumentChooserViewUr extends DocumentChooserView {

  initialize(options) {
    super.initialize(options);
    this.sessionModel = options.sessionModel;
  }

  getHeaderMenuEntries() {
    const entries = super.getHeaderMenuEntries();
    entries.splice(1, 0, MenuFactory.createImprintEntry());
    return entries;
  }

  createRowMenuEntries(taxData, document, editHref, submissionHref) {
    // remove default export action (for chosen ones) and add custom one, for everyman
    taxData.exportAction = false;
    const menuEntries = DocumentChooserView.prototype.createRowMenuEntries.call(this, taxData, document, editHref, submissionHref)
    menuEntries.push({
      children: $.i18n("document-chooser.action.export"),
      props: {
        icon: {
          props: {
            name: "square-download",
          },
        },
        href: appConfiguration.servicesBaseUrl + appConfiguration.urChannel + '/export/' + document.id
      },
    })
    const meta = document.metaData;
    const modelId = document.model;
    if (meta && meta.taxYear && !document.submissions && !submissionHref) {
      const isJp = modelId.endsWith('.8') || modelId.endsWith('.4')
      let url = isJp ? appConfiguration.taxDeadlineExtendUrl.jp : appConfiguration.taxDeadlineExtendUrl.np;
      menuEntries.splice(1, 0, {
        children: $.i18n("document-chooser.action.extend-tax-deadline"),
        props: {
          icon: {
            props: {
              name: "refresh",
            },
          },
          href: url,
          target: '_blank'
        },
      })
    }
    return menuEntries;
  }

}

class DocumentChooserControllerUr extends DocumentChooserController {

  createDocumentChooserView(options) {
    options.centralAddButton = true;
    options.centralAddButtonAlwaysVisible = true;
    options.centralContextMenu = true;
    options.sessionModel = this.sessionModel;
    options.taxDeadlineExtendUrl = "https://www.ur.ch/online-schalter/2717/detail"
    return new DocumentChooserViewUr(options);
  }

}

export default DocumentChooserControllerUr;
